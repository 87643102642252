import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '../i18n';
import vuetify from './plugins/vuetify';
import HtmlToPaper from './plugins/HtmlToPaper';
import VueMask from 'v-mask';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueMeta from 'vue-meta';
import YandexMap from '@/plugins/yandexMap';
import VueNestable from '@/plugins/vue-nestable';
import currencyLocaliziton from '@/plugins/currencyLocaliziton';
import './registerServiceWorker';

Vue.use(VueMask);
Vue.use(CKEditor);
Vue.use(VueMeta);

new Vue({
  router,
  HtmlToPaper,
  VueNestable,
  currencyLocaliziton,
  store,
  i18n,
  YandexMap,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
