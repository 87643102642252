<template>
  <v-app>
    <v-main class="main">
      <router-view
        @startedFileCreate="openLoadingSnackbar"
        v-if="loadingData"
      />
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="snackbarType == 'green' ? 'green' : 'red'"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="loadingSnackbar" :timeout="9000000">
        {{ $t('theFilePreparationProcessHasStarted') }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="loadingSnackbar = false"
          >
            {{ $t('close') }}
          </v-btn>
        </template>
        <v-progress-linear absolute bottom indeterminate></v-progress-linear>
      </v-snackbar>
      <v-dialog v-model="internalErrorDialog" max-width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            500 Server internal error
          </v-card-title>

          <v-card-text>
            {{ snackbarText }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="internalErrorDialog = false">
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import store from '@/store';
import api from '@/api/api';
import { mapGetters } from 'vuex';
import soket from '@/Mixins/downloadFileSoketMixsin';

export default {
  name: 'App',
  metaInfo: {
    title: '',
  },
  data() {
    return {
      snackbarText: '',
      snackbarType: 'green',
      snackbar: false,
      currentSoket: soket,
      loadingSnackbar: false,
      loadingData: false,
      internalErrorDialog: false,
    };
  },
  computed: {
    ...mapGetters(['userAccess', 'userInfo']),
    theme() {
      return store.state.theme;
    },
  },
  watch: {
    theme(newVal, oldVal) {
      document
        .querySelector('body')
        .classList.add(oldVal === 'light' ? 'dark' : 'light');
      document
        .querySelector('body')
        .classList.remove(oldVal !== 'light' ? 'dark' : 'light');
    },
    $route(to) {
      const { meta } = to;
      if (meta && meta.accessModul) {
        this.checkPermissions(meta.accessModul);
      } else if (meta.requiresAuth == true) {
        this.$router.push({ name: '403Views' });
      }
    },
  },
  methods: {
    setWithExpiry(key, value, ttl) {
      const now = new Date();

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    checkPermissions(modul) {
      if (this.userAccess == 'noLoad') {
        setTimeout(() => {
          this.checkPermissions(modul);
        }, 150);
        return;
      }
      if (modul == 'access.true' || this.userAccess[modul] == true) {
        //
      } else {
        this.$router.push({ name: '403Views' });
      }
    },
    showError(message) {
      if (message.mode == 'dialog') {
        this.internalErrorDialog = true;
        this.snackbarText = message.detail;
      } else {
        this.snackbarText = message.detail;
        this.snackbarType = message.type;
        this.snackbar = true;
      }
    },
    async endProgressFunc(event) {
      this.loadingSnackbar = false;
      const { data } = event;
      const fileId = JSON.parse(data || '{}').export_file_id;
      const { data: response } = await api.getFileData(fileId);
      if (response.file) {
        window.open(response.file);
      }
    },
    openLoadingSnackbar() {
      this.loadingSnackbar = true;
    },
  },
  mounted() {
    if (location.pathname != '/') {
      this.currentSoket.newConnection(this.endProgressFunc);
    }
    document.querySelector('body').classList.add(this.theme);
    api.onMessage(this.showError);
  },
  async created() {
    if (!this.getWithExpiry('currencyUSD')) {
      const { data } = await api.getCurrencies();

      const usdCurrencies = data.find((items) => items.Ccy == 'USD');
      this.setWithExpiry('currencyUSD', usdCurrencies.Rate, 3600000);
    }
    this.loadingData = true;
  },
};
</script>
