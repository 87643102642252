import axios from 'axios';
import { baseUrl } from '@/config';
import router from '@/router';
import axiosRetry from 'axios-retry';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from 'axios-extensions';
const curLang = localStorage.getItem('language') || 'uz';
const localization = require(`@/locales/${curLang}.json`);

axios.defaults.baseURL = baseUrl;
axios.defaults.headers['Accept-Language'] = curLang == 'kr' ? 'cy' : curLang;

axios.defaults.adapter = throttleAdapterEnhancer(
  cacheAdapterEnhancer(axios.defaults.adapter)
);

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('access');

  axios.defaults.headers['Accept-Language'] = curLang == 'kr' ? 'cy' : curLang;
  if (token && !config.url.startsWith('/v3/token/refresh/')) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosRetry(axios, {
  retries: 3,
  retryCondition: (event) => {
    if (
      event.config.method == 'get' &&
      event.response.status !== 500 &&
      event.response.status !== 400 &&
      event.response.status !== 404 &&
      event.response.status !== 401
    ) {
      return true;
    }
    return false;
  },
});

export default {
  listeners: {
    messages: [],
    progresses: [],
  },
  async request({
    url,
    data,
    params,
    method,
    fileType = false,
    fileUploadType = false,
  }) {
    let response = null;
    try {
      const body = {
        method,
        url,
        data,
        params,
      };
      if (fileUploadType) {
        axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      }
      if (fileType) {
        body.responseType = 'blob';
      }
      response = await axios(body);
      return response;
    } catch (error) {
      let str = '';
      if (error.message == 'Network Error') {
        str = localization.errorConnectingToServer;
      } else if (
        error.response &&
        error.response.status === 401 &&
        url !== '/v3/token/' &&
        url !== '/v3/token/refresh/'
      ) {
        if (await this.refreshToken()) {
          localStorage.clear();

          window.location.replace('/');
          return { error: 'refresh expired' };
        }
        response = await this.request({
          url,
          data,
          params,
          method,
        });
        return response;
      }
      if (error.response !== undefined) {
        if (
          error.response.data &&
          error.response.data.code === 'token_not_valid'
        ) {
          localStorage.clear();
          router.push({ path: '/' });
        }

        if (error.response.status === 500) {
          str = localization.serverError;
        }
        str = JSON.stringify(error.response.data || 'Network error')
          .replaceAll(/["{}]/g, '')
          .replaceAll('[', '')
          .replaceAll(']', '');
        if (error.response.status !== 403) {
          this.emitMessage({
            detail: str,
            type: 'error',
            mode: error.response.status === 500 ? 'dialog' : 'default',
          });
        }
      }
      this.emitMessage({
        detail: str,
        type: 'error',
      });
      return {
        error: {
          detail: error.response,
        },
      };
    }
  },
  get(url, params, fileType = false, msg = null) {
    const response = this.request({
      method: 'get',
      url,
      params,
      fileType,
    });
    if (!response.error && msg) {
      this.emitMessage({
        detail: msg.text,
        type: msg.type,
      });
    }
    return response;
  },
  async refreshToken() {
    const refresh = localStorage.getItem('refresh');
    if (refresh) {
      try {
        const { data } = await this.post(
          '/v3/token/refresh/',
          {
            refresh,
          },
          false,
          false
        );
        if (data) {
          localStorage.setItem('access', data.access);
        } else {
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('theme');
          return true;
        }
        return false;
      } catch (error) {
        if (error !== undefined) {
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
          localStorage.removeItem('userInfo');
          localStorage.removeIteme('theme');
          return true;
        }
      }
    }
    return true;
  },
  async post(
    url,
    data,
    fileUploadType = false,
    showMessege = true,
    msg = null
  ) {
    const response = await this.request({
      method: 'post',
      url,
      data,
      fileUploadType,
    });

    if (!response.error && showMessege) {
      if (msg) {
        this.emitMessage({
          detail: msg.text,
          type: msg.type,
        });
      } else {
        this.emitMessage({
          detail: localization.successfullyCreated,
          type: 'green',
        });
      }
    }
    return response;
  },
  async put(url, data, showMessege = true) {
    const response = await this.request({
      method: 'put',
      url,
      data,
    });
    if (!response.error && showMessege) {
      this.emitMessage({
        detail: localization.successfullyUpdated,
        type: 'green',
      });
    }
    return response;
  },
  async patch(url, data, showMessege = true) {
    const response = await this.request({
      method: 'patch',
      url,
      data,
    });
    if (!response.error && showMessege) {
      this.emitMessage({
        detail: localization.successfullyModified,
        type: 'green',
      });
    }
    return response;
  },
  async delete(url, data) {
    const response = await this.request({
      method: 'delete',
      url,
      data,
    });
    if (!response.error) {
      this.emitMessage({
        detail: localization.successfullyDeleted,
        type: 'green',
      });
    }
    return response;
  },
  async getuserData() {
    return this.get(`/v3/profile/`);
  },
  async userLogin(params) {
    return this.post('/v3/token/', params, false, false);
  },
  async getOrdersData(params) {
    return this.get(`/v3/dashboard/orders/`, params);
  },
  async getCustomersData(params) {
    return this.get('/v3/customers/', params);
  },
  async getSelesPersonList(params) {
    return this.get('/v3/dashboard/sales-persons/', params);
  },
  async getOrderData(id, params) {
    return this.get(`/v3/dashboard/orders/${id}/`, params);
  },
  async getOrdersStatus(params) {
    return this.get('/v3/dashboard/order-statuses/', params);
  },
  async getSelectedSecondForm(params, msg) {
    return this.get(
      '/v3/dashboard/orders/download/second-form/',
      params,
      false,
      msg
    );
  },
  async getSelectedFirstForm(params) {
    return this.get('/v3/dashboard/orders/download/first-form/', params, false);
  },
  async getUsersData(params) {
    return this.get('/v3/dashboard/users/', params);
  },
  async changeOrdersStatus1C(params) {
    return this.post('/v3/dashboard/orders/change/status/', params);
  },
  async getMonthlySale(params) {
    return this.get('/v3/dashboard/purchases/monthly/', params);
  },
  async getMonthlyOrder(params) {
    return this.get('/v3/dashboard/stat/orders-count/', params);
  },
  async getRolesData(params) {
    return this.get('/v3/dashboard/roles/', params);
  },
  async addRole(data) {
    return this.post('/v3/dashboard/roles/', data);
  },
  async editRole(id, data) {
    return this.put(`/v3/dashboard/role/${id}/`, data);
  },
  async getRoleData(id, params) {
    return this.get(`/v3/dashboard/role/${id}/`, params);
  },
  async deleteRole(id) {
    return this.delete(`/v3/dashboard/role/${id}/`);
  },
  async changeUserImage(body) {
    return this.patch('/v3/profile/', body);
  },
  async changePassword(body) {
    return this.put('/v3/profile/password/', body);
  },
  async updateUserData(body) {
    return this.patch('/v3/profile/', body);
  },
  async downloadUsersData(params) {
    return this.get('/v3/dashboard/users/export/', params, false);
  },
  async getLogsData(params) {
    return this.get('/v3/dashboard/logs/', params);
  },
  async getOrderLogDetail(id, params) {
    return this.get(`/v3/dashboard/logs/${id}`, params);
  },
  async downloadLogsData(params) {
    return this.get('/v3/dashboard/logs/export/', params, false);
  },
  async searchUser(params) {
    return this.get('/v3/dashboard/users/', params);
  },
  async getDealers(params) {
    return this.get('/v3/dashboard/orders/dealers-stat/', params);
  },
  async getRegions(params) {
    return this.get('/v3/regions/', params);
  },
  async getProducts(params) {
    return this.get('/v3/dashboard/products/', params);
  },
  async getProductsChart(id, params) {
    return this.get(`/v3/dashboard/products/${id}/chart-statistics/`, params);
  },
  async changeUser(data, msg) {
    return this.post(
      '/v3/dashboard/users/change/user-type/',
      data,
      false,
      true,
      msg
    );
  },
  async getSelectedFile(params) {
    return this.get('/v3/dashboard/users/export/', params);
  },
  async getUserTypes() {
    return this.get('/v3/user-types/');
  },
  async getFilesData(params) {
    return this.get('/v3/dashboard/export-files/', params);
  },
  async getFileData(id) {
    return this.get(`/v3/dashboard/export-files/${id}/`);
  },
  async getSelectedProduct(params) {
    return this.get('/v3/dashboard/products/export/', params, false, false);
  },
  async getClassTypesData(params) {
    return this.get('/v3/class-types/', params);
  },
  async createUser(data) {
    return this.post('/v3/dashboard/users/', data);
  },
  async sendSMS(data) {
    return this.post(`/v3/dashboard/users/send-sms/`, data);
  },
  async getUserData(id, params) {
    return this.get(`/v3/dashboard/users/${id}/`, params);
  },
  async editUserData(id, data) {
    return this.put(`/v3/dashboard/users/${id}/`, data);
  },
  async uploadExelUsersFile(data) {
    return this.post('/v3/dashboard/users/import/', data);
  },
  async getBrandsData(params) {
    return this.get('/v3/brands/', params);
  },
  async getCategorysFirstData(params) {
    return this.get('/v3/categories/list', params);
  },
  async getCategorysSecondData(id, params) {
    return this.get(`/v3/categories/${id}`, params);
  },
  async getCategorysThreeData(rootId, id, params) {
    return this.get(`/v3/categories/${rootId}/${id}`, params);
  },
  async uploadProductImage(id, data) {
    return this.post(`/v3/dashboard/products/${id}/images/`, data, true, false);
  },
  async deleteproductImage(productId, imageId) {
    return this.delete(
      `/v3/dashboard/products/${productId}/images/${imageId}/`
    );
  },
  async getSpecificationsData(params) {
    return this.get('/v3/dashboard/specifications-types/', params);
  },
  async addProduct(data) {
    return this.post('/v3/dashboard/products/create/', data, true);
  },
  async getProductData(id, params) {
    return this.get(`/v3/dashboard/products/${id}/`, params);
  },
  async getCategoryData(id, params) {
    return this.get(`/v3/categories/detail/${id}`, params);
  },
  async deleteProduct(id) {
    return this.delete(`/v3/dashboard/products/${id}/`);
  },
  async editProdcut(id, data) {
    return this.patch(`/v3/dashboard/products/${id}/`, data);
  },
  async addProductsGoogleDrive(params) {
    return this.post(
      '/v3/dashboard/products/download-from-google-drive/',
      params
    );
  },
  async getSpecificationData(id) {
    return this.get(`/v3/dashboard/specifications-types/${id}/`);
  },
  async addSpecification(params) {
    return this.post('/v3/dashboard/specifications-types/', params);
  },
  async editSpecification(id, params) {
    return this.patch(`/v3/dashboard/specifications-types/${id}/`, params);
  },
  async deleteSpecificationType(id) {
    return this.delete(`/v3/dashboard/specifications-types/${id}/`);
  },
  async addRegion(data) {
    return this.post('/v3/dashboard/regions/', data);
  },
  async editRegion(id, data) {
    return this.patch(`/v3/dashboard/regions/${id}/`, data);
  },
  async deleteRegion(id) {
    return this.delete(`/v3/regions/${id}/`);
  },
  async getFedbackData(params) {
    return this.get('/v3/dashboard/products/reviews/', params);
  },
  async changeFedbackConfirmend(params, msg) {
    return this.post(
      '/v3/dashboard/products/reviews/change-confirmed/',
      params,
      false,
      true,
      msg
    );
  },
  async getConfirmationCodes(params) {
    return this.get('/v3/dashboard/verification-codes/', params);
  },
  async getCashbacksData(params) {
    return this.get('/v3/dashboard/users/cashbacks/', params);
  },
  async getUsersClassTypesData(params) {
    return this.get('/v3/dashboard/users/class-types/', params);
  },
  async createUserType(data) {
    return this.post('/v3/dashboard/users/class-types/', data);
  },
  async editUserType(id, data) {
    return this.patch(`/v3/dashboard/users/class-types/${id}/`, data);
  },
  async deleteUserType(id, data) {
    return this.delete(`/v3/dashboard/users/class-types/${id}/`, data);
  },
  async getCategorysData(params) {
    return this.get('/v3/dashboard/categories/', params);
  },
  async getCategorysListData(params) {
    return this.get('/v3/dashboard/categories/list/', params);
  },
  async addCategory(data) {
    return this.post('/v3/dashboard/categories/', data);
  },
  async editCategory(id, data, showMessege = true) {
    return this.patch(`/v3/dashboard/categories/${id}/`, data, showMessege);
  },
  async getCategory(id, params) {
    return this.get(`/v3/dashboard/categories/${id}/`, params);
  },
  async deleteCategory(id, params) {
    return this.delete(`/v3/dashboard/categories/${id}/`, params);
  },
  async getWarehouseData(params) {
    return this.get('/v3/dashboard/warehouses/', params);
  },
  async createWarehouseData(data) {
    return this.post('/v3/dashboard/warehouses/', data);
  },
  async editWarehouseData(id, data) {
    return this.patch(`/v3/dashboard/warehouses/${id}/`, data);
  },
  async deleteWarehouseData(id) {
    return this.delete(`/v3/dashboard/warehouses/${id}/`);
  },
  async getBotChatsData(params) {
    return this.get('/v3/dashboard/bot-chats/', params);
  },
  async getBotChatData(id, params) {
    return this.get(`/v3/dashboard/bot-chats/${id}/`, params);
  },
  async editBotChat(id, data) {
    return this.patch(`/v3/dashboard/bot-chats/${id}/`, data);
  },
  async deleteBotChat(id) {
    return this.delete(`/v3/dashboard/bot-chats/${id}/`);
  },
  async getBlogsData(params) {
    return this.get('/v3/dashboard/blogs/', params);
  },
  async addBlogData(data) {
    return this.post('/v3/dashboard/blogs/', data);
  },
  async getBlogData(id, params) {
    return this.get(`/v3/dashboard/blogs/${id}/`, params);
  },
  async editBlogData(id, data) {
    return this.patch(`/v3/dashboard/blogs/${id}/`, data);
  },
  async deleteBlogData(id) {
    return this.delete(`/v3/dashboard/blogs/${id}/`);
  },
  async getStoresData(params) {
    return this.get('/v3/dashboard/stores/', params);
  },
  async getContentsData(params) {
    return this.get('/v3/dashboard/contents/', params);
  },
  async getContentData(id, params) {
    return this.get(`/v3/dashboard/contents/${id}/`, params);
  },
  async addContentData(data) {
    return this.post('/v3/dashboard/contents/', data);
  },
  async editContentData(id, data) {
    return this.patch(`/v3/dashboard/contents/${id}/`, data);
  },
  async deleteContentData(id) {
    return this.delete(`/v3/dashboard/contents/${id}/`);
  },
  async getSlidersData(params) {
    return this.get(`/v3/dashboard/sliders/`, params);
  },
  async addSliderData(data) {
    return this.post(`/v3/dashboard/sliders/`, data);
  },
  async editSliderData(id, data) {
    return this.patch(`/v3/dashboard/sliders/${id}/`, data);
  },
  async deleteSliderData(id) {
    return this.delete(`/v3/dashboard/sliders/${id}/`);
  },
  async updateProducts(data) {
    return this.put(`/v3/dashboard/products/update/`, data);
  },
  async getMobileVersions(params) {
    return this.get('/v3/dashboard/mobile-versions/', params);
  },
  async getMobileVersion(id, params) {
    return this.get(`/v3/dashboard/mobile-versions/${id}/`, params);
  },
  async addMobileVersionsData(data) {
    return this.post('/v3/dashboard/mobile-versions/', data);
  },
  async editMobileVersionsData(id, data) {
    return this.patch(`/v3/dashboard/mobile-versions/${id}/`, data);
  },
  async deleteMobileVersionsData(id) {
    return this.delete(`/v3/dashboard/mobile-versions/${id}`);
  },
  async deleteStoresData(id) {
    return this.delete(`/v3/dashboard/stores/${id}`);
  },
  async createStoreData(data) {
    return this.post(`/v3/dashboard/stores/`, data);
  },
  async editStoresData(id, data) {
    return this.patch(`/v3/dashboard/stores/${id}/`, data);
  },
  async getZakupListData(params) {
    return this.get(`/v3/dashboard/purchases/`, params);
  },
  async deleteFeedback(id) {
    return this.delete(`/v3/dashboard/products/reviews/${id}/`);
  },
  async downloadSelectedZakupList(params) {
    return this.get('/v3/dashboard/purchases/export/', params, false);
  },
  async uploadZakupList(params) {
    return this.post('/v3/dashboard/purchases/import/', params, true);
  },
  async getZakupList(id, params) {
    return this.get(`/v3/dashboard/purchases/${id}/`, params);
  },
  async editZakupListData(id, data) {
    return this.patch(`/v3/dashboard/purchases/${id}/`, data);
  },
  async getSpecifications(params) {
    return this.get('/v3/dashboard/specifications/', params);
  },
  async createSpecification(params) {
    return this.post('/v3/dashboard/specifications/', params);
  },
  async getSpecification(id) {
    return this.get(`/v3/dashboard/specifications/${id}/`);
  },
  async editSpecificationData(id, data) {
    return this.patch(`/v3/dashboard/specifications/${id}/`, data);
  },
  async deleteSpecification(id) {
    return this.delete(`/v3/dashboard/specifications/${id}/`);
  },
  async getSpecificationsAndCategories(params) {
    return this.get('/v3/dashboard/specifications-types-categories/', params);
  },
  async addSpecificationAndCategory(data) {
    return this.post('/v3/dashboard/specifications-types-categories/', data);
  },
  async editSpecificationAndCategory(id, data) {
    return this.patch(
      `/v3/dashboard/specifications-types-categories/${id}/`,
      data
    );
  },
  async deleteSpecificationAndCategory(id) {
    return this.delete(`/v3/dashboard/specifications-types-categories/${id}/`);
  },
  async getCardItemsData(params) {
    return this.get(`/v3/dashboard/cart-items/`, params);
  },
  async deleteCardItems(id) {
    return this.delete(`/v3/dashboard/cart-items/${id}`);
  },
  async deleteConifrmCodes(id) {
    return this.delete(`/v3/dashboard/verification-codes/${id}/`);
  },
  async uploadSpecificationsProduct(id, data) {
    return this.post(
      `/v3/dashboard/products/${id}/specifications/import/`,
      data
    );
  },
  async downloadProductCatalog(params) {
    return this.post(
      '/v3/dashboard/products/download/catalog/',
      params,
      false,
      false
    );
  },
  async categoryReOrder(data) {
    return this.put('/v3/dashboard/categories/update/', data);
  },
  async getBrandsList(params) {
    return this.get('/v3/brands/', params);
  },
  async getCategoriesList() {
    return this.get('/v3/categories/list');
  },
  async changeProductStatus(params) {
    return this.put('/v3/dashboard/products/change-available/', params);
  },
  async generateProductLink(data) {
    return this.post('/v3/dashboard/products/generate-telegraph-pages/', data);
  },
  async downloadFromGDrive(data) {
    return this.post(
      '/v3/dashboard/products/download-from-google-drive/',
      data
    );
  },
  async getBrandsInfo(params) {
    return this.get('/v3/dashboard/brands/', params);
  },
  async createBrand(data) {
    return this.post('/v3/dashboard/brands/', data);
  },
  async getSingleBrand(id) {
    return this.get(`/v3/dashboard/brands/${id}/`);
  },
  async updateBrand(data, id) {
    return this.patch(`/v3/dashboard/brands/${id}/`, data);
  },
  async removeSingleBrand(id) {
    return this.delete(`/v3/dashboard/brands/${id}/`);
  },
  async getSingleCashBack(id) {
    return this.get(`/v3/dashboard/users/cashbacks/${id}/`);
  },
  async getAdvertise(data) {
    return this.get('/v3/dashboard/advertisements/', data);
  },
  async createAdvertise(data) {
    return this.post('/v3/dashboard/advertisements/', data);
  },
  async getSingleAds(id) {
    return this.get(`/v3/dashboard/advertisements/${id}/`);
  },
  async updateAds(data, id) {
    return this.patch(`/v3/dashboard/advertisements/${id}/`, data);
  },
  async deleteSingleAdvertise(id) {
    return this.delete(`/v3/dashboard/advertisements/${id}/`);
  },
  async getCategoryBrands(data) {
    return this.get('/v3/dashboard/category-brands/', data);
  },
  async getBrands() {
    return this.get('/v3/dashboard/brands/');
  },
  async getCategories(data) {
    return this.get('/v3/dashboard/categories/', data);
  },
  async createCategoryAndBrand(data) {
    return this.post('/v3/dashboard/category-brands/', data);
  },
  async getSingleCategoryBrand(id) {
    return this.get(`/v3/dashboard/category-brands/${id}`);
  },
  async deleteCategoryBrand(id) {
    return this.delete(`/v3/dashboard/category-brands/${id}`);
  },
  async updateCategoryBrand(data, id, showMessage = true) {
    return this.patch(
      `/v3/dashboard/category-brands/${id}/`,
      data,
      showMessage
    );
  },
  async getMenus(data) {
    return this.get('/v3/dashboard/menus/', data);
  },
  async getSingleMenu(id) {
    return this.get(`/v3/dashboard/menus/${id}/`);
  },
  async createMenus(data) {
    return this.post('/v3/dashboard/menus/', data);
  },
  async updateSingleMenu(data, id) {
    return this.put(`/v3/dashboard/menus/${id}/`, data);
  },
  async deleteMenu(id) {
    return this.delete(`/v3/dashboard/menus/${id}/`);
  },
  async changeProductsAvailable(data) {
    return this.post('/v3/dashboard/products/change-available/', data);
  },
  async editOrder(id, data) {
    return this.patch(`/v3/dashboard/orders/${id}/`, data);
  },
  async importProducts(file) {
    return this.post('/v3/dashboard/products/import/', file, true);
  },
  async getOrderLogs(id, params) {
    return this.get(`/v3/dashboard/orders/${id}/logs/`, params);
  },
  async getUserSpecGroups(params) {
    return this.get('/v3/dashboard/users/spec-groups/', params);
  },
  async addSpecGroup(data) {
    return this.post('/v3/dashboard/users/spec-groups/', data);
  },
  async editSpecGroup(id, data) {
    return this.patch(`/v3/dashboard/users/spec-groups/${id}/`, data);
  },
  async deleteSpecGroup(id, data) {
    return this.delete(`/v3/dashboard/users/spec-groups/${id}/`, data);
  },
  async sendChatZakubListData(data, msg) {
    return this.post(`/v3/dashboard/purchases/send/`, data, false, true, msg);
  },
  async reduceProductstoZero(data) {
    return this.post(`/v3/dashboard/products/quantity-update-to-zero/`, data);
  },
  async generatePurchaseForm(data) {
    return this.post(`/v3/dashboard/products/generate-purchase-form/`, data);
  },
  async getUserLocaitonData(params) {
    return this.get(`/v4/users/locations/`, params);
  },
  async getDealersList(params) {
    return this.get('v3/dashboard/users/dealers/', params);
  },
  async getUsersMapExcel(data) {
    return this.post(
      'v3/dashboard/users/locations/export/',
      data,
      false,
      false
    );
  },
  async getV4Regions(params) {
    return this.get('/v4/regions/', params);
  },
  async getShopsData(params) {
    return this.get('/v3/dashboard/shops/', params);
  },
  getOrdersAmount(params) {
    return this.get('/v3/dashboard/stat/orders-amount/', params);
  },
  getCurrencies(params) {
    return this.get('/currencies/', params);
  },
  async getShopsLocaitonData(params) {
    return this.get('/v3/dashboard/shops/maps/', params);
  },
  getOrderLocaitonData(id, params) {
    return this.get(`/v3/dashboard/orders/${id}/shop/`, params);
  },
  downloadSelectedShopsList(params) {
    return this.get('/v3/dashboard/shops/export/', params, false);
  },
  uploadShopsList(params) {
    return this.post('/v3/dashboard/shops/import/', params, true);
  },
  changeUserClassType(params, msg) {
    return this.post(
      '/v3/dashboard/users/change/class-type/',
      params,
      false,
      true,
      msg
    );
  },
  uploadFileTheBotChats(id, data, msg) {
    return this.post(
      `/v3/dashboard/bot-chats/${id}/import-products/`,
      data,
      true,
      true,
      msg
    );
  },
  changeBrandsPosition(data, msg) {
    return this.post('/v3/dashboard/brands/update/', data, false, true, msg);
  },
  getProductLogsData(id, params) {
    return this.get(`/v3/dashboard/products/${id}/logs/`, params);
  },
  syncGooogleSheets(data, msg) {
    return this.post(
      '/v3/dashboard/products/sync-products-from-google-sheets/',
      data,
      false,
      true,
      msg
    );
  },
  sendPhoneSmsCode(data) {
    return this.post(`/users/verification-code/`, data, false, false);
  },
  checksmsCode(data) {
    return this.post(`/users/token/`, data, false, false);
  },
  getDashboardRegions(params) {
    return this.get('/v3/dashboard/regions/', params);
  },
  getSuppliersData(params) {
    return this.get('/v3/dashboard/suppliers/', params);
  },
  getSupplierData(id, params) {
    return this.get(`/v3/dashboard/suppliers/${id}/`, params);
  },
  createSupplier(data) {
    return this.post('/v3/dashboard/suppliers/', data);
  },
  editSupplier(id, data) {
    return this.patch(`/v3/dashboard/suppliers/${id}/`, data);
  },
  deleteSuppliers(id) {
    return this.delete(`/v3/dashboard/suppliers/${id}/`);
  },
  sendSMSCode(data) {
    return this.post('/users/verification-code/', data, false, false);
  },
  resetPassword(data, msg) {
    return this.post('/users/password/change/', data, false, true, msg);
  },
  checkUserPhone(data) {
    return this.post('/users/phone/verification/', data, false, false);
  },
  getContractData(params) {
    return this.get('/v3/dashboard/suppliers/contracts/', params);
  },
  getProductHistorysData(id, params) {
    return this.get(`/v3/dashboard/products/${id}/history/`, params);
  },
  getProductHistoryData(productId, historyId) {
    return this.get(
      `/v3/dashboard/products/${productId}/history/${historyId}/`
    );
  },
  getRequisites(params) {
    return this.get('/v3/dashboard/suppliers/requisites/', params);
  },
  getAdditionalInformation(params) {
    return this.get('/v3/dashboard/suppliers/extra-infos/', params);
  },
  uploadFileRequisites(id, data) {
    return this.post(
      `/v3/dashboard/suppliers/${id}/import-requisites/`,
      data,
      true,
      true
    );
  },
  uploadFileAdditionalInformation(id, data) {
    return this.post(
      `/v3/dashboard/suppliers/${id}/import-extra-infos/`,
      data,
      true,
      true
    );
  },
  emitMessage(message) {
    this.listeners.messages.forEach((listener) => listener(message));
  },
  onMessage(listener) {
    const { listeners } = this;
    if (listeners.messages.includes(listener)) {
      return;
    }
    listeners.messages.push(listener);
  },
  offMessage(listener) {
    this.listeners.messages = this.listeners.messages.filter(
      (el) => el !== listener
    );
  },
};
