let currentSoket = null;
import { baseUrl } from '@/config';
const user = JSON.parse(localStorage.getItem('userInfo') || '[]');
const access = localStorage.getItem('access');

export default {
  newConnection(endProgressFunc) {
    currentSoket = new WebSocket(
      `wss://${baseUrl.replace('https://', '')}/ws/notification/${
        user.id
      }/?access_token=${access}`
    );

    currentSoket.onmessage = (event) => {
      endProgressFunc(event);
    };
    currentSoket.onopen = function () {
      console.log('Successfully connected to the echo websocket server...');
    };
  },
};
