import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/LoginView'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/DahsboardView'),
    meta: {
      requiresAuth: true,
    },
    redirect: '/main',
    children: [
      {
        path: '/main',
        name: 'MainView',
        component: () => import('@/views/MainView'),
        meta: {
          requiresAuth: true,
          accessModul: 'main.view',
        },
      },
      {
        path: '/brands',
        name: 'BrandsView',
        component: () => import('@/views/BrandsView'),
        meta: {
          requiresAuth: true,
          accessModul: 'brands.view',
        },
      },
      {
        path: '/brands/edit/:id',
        name: 'BrandsEditView',
        component: () => import('@/views/BrandsView/BrandsEdit'),
        meta: {
          requiresAuth: true,
          accessModul: 'brands.edit',
        },
      },
      {
        path: '/brands/create',
        name: 'CreateBrandsView',
        component: () => import('@/views/BrandsView/CreateBrands'),
        meta: {
          requiresAuth: true,
          accessModul: 'brands.create',
        },
      },
      {
        path: '/advertisements',
        name: 'AdvertisementsView',
        component: () => import('@/views/AdvertisementsView'),
        meta: {
          requiresAuth: true,
          accessModul: 'advertisements.view',
        },
      },
      {
        path: '/advertisements/create',
        name: 'CreateAdvertise',
        component: () => import('@/views/AdvertisementsView/CreateAdvertise'),
        meta: {
          requiresAuth: true,
          accessModul: 'advertise.create',
        },
      },
      {
        path: '/advertisements/edit/:id',
        name: 'EditAdvertise',
        component: () => import('@/views/AdvertisementsView/EditAdvertise'),
        meta: {
          requiresAuth: true,
          accessModul: 'advertise.edit',
        },
      },
      {
        path: '/category-brand',
        name: 'CategoryBrandsView',
        component: () =>
          import('@/views/CategoryBrandsView/CategoryBrandsView'),
        meta: {
          requiresAuth: true,
          accessModul: 'categorybrands.view',
        },
      },
      {
        path: '/category-brand/create',
        name: 'CategoryBrandsCreate',
        component: () =>
          import('@/views/CategoryBrandsView/CategoryBrandsCreate'),
        meta: {
          requiresAuth: true,
          accessModul: 'categorybrands.create',
        },
      },
      {
        path: '/category-brand/edit/:id',
        name: 'CategoryBrandsEdit',
        component: () =>
          import('@/views/CategoryBrandsView/CategoryBrandsEdit'),
        meta: {
          requiresAuth: true,
          accessModul: 'categorybrands.edit',
        },
      },
      {
        path: '/menu-page',
        name: 'MenusPageView',
        component: () => import('@/views/MenusPageView'),
        meta: {
          requiresAuth: true,
          accessModul: 'menuspage.view',
        },
      },
      {
        path: '/menu-page/create',
        name: 'MenusCreatePage',
        component: () => import('@/views/MenusPageView/MenusCreatePage'),
        meta: {
          requiresAuth: true,
          accessModul: 'menuspage.create',
        },
      },
      {
        path: '/menu-page/edit/:id',
        name: 'MenusPageEdit',
        component: () => import('@/views/MenusPageView/MenusPageEdit'),
        meta: {
          requiresAuth: true,
          accessModul: 'menuspage.edit',
        },
      },
      {
        path: '/roles',
        name: 'RolesView',
        component: () => import('@/views/RolesView'),
        meta: {
          requiresAuth: true,
          accessModul: 'roles.view',
        },
      },
      {
        path: '/confirmation-codes',
        name: 'ConfirmationCodes',
        component: () => import('@/views/ConfirmationCodes'),
        meta: {
          accessModul: 'confirmation.view',
          requiresAuth: true,
        },
      },
      {
        path: '/feedback',
        name: 'FeedBackView',
        component: () => import('@/views/FeedBackView'),
        meta: {
          accessModul: 'feedback.view',
          requiresAuth: true,
        },
      },
      {
        path: '/logs',
        name: 'LogsView',
        component: () => import('@/views/LogsView'),
        meta: {
          accessModul: 'logs.view',
          requiresAuth: true,
        },
      },
      {
        path: '/files',
        name: 'FilesView',
        component: () => import('@/views/FilesViews'),
        meta: {
          accessModul: 'access.true',
          requiresAuth: true,
        },
      },
      {
        path: '/category',
        name: 'CategoryView',
        component: () => import('@/views/CategoryView'),
        meta: {
          accessModul: 'category.view',
          requiresAuth: true,
        },
      },
      {
        path: '/category/create/:id?',
        name: 'CreateCategory',
        component: () => import('@/views/CategoryView/CreateCategoryView'),
        meta: {
          accessModul: 'category.create',
          requiresAuth: true,
        },
      },
      {
        path: '/category/edit/:id',
        name: 'EditCategory',
        component: () => import('@/views/CategoryView/EditCategoryView'),
        meta: {
          accessModul: 'category.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/category/more/:id',
        name: 'MoreCategory',
        component: () => import('@/views/CategoryView/MoreCategoryView'),
        meta: {
          accessModul: 'category.more',
          requiresAuth: true,
        },
      },
      {
        path: '/region',
        name: 'RegionView',
        component: () => import('@/views/RegionView'),
        meta: {
          accessModul: 'regions.view',
          requiresAuth: true,
        },
      },
      {
        path: '/products',
        name: 'ProductsView',
        component: () => import('@/views/ProductsView'),
        meta: {
          requiresAuth: true,
          accessModul: 'products.view',
        },
      },
      {
        path: '/products/create',
        name: 'ProductsCreateView',
        component: () => import('@/views/ProductsView/CreateProductViews'),
        meta: {
          requiresAuth: true,
          accessModul: 'products.create',
        },
      },
      {
        path: '/products/edit/:id',
        name: 'ProductsEditView',
        component: () => import('@/views/ProductsView/EditProductView'),
        meta: {
          requiresAuth: true,
          accessModul: 'products.edit',
        },
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/views/OrdersView'),
        meta: {
          requiresAuth: true,
          accessModul: 'orders.view',
        },
      },
      {
        path: '/orders/map/:id',
        name: 'OrdersMap',
        component: () => import('@/views/OrdersView/OrdersMapViews'),
        meta: {
          requiresAuth: true,
          accessModul: 'orders.view',
        },
      },
      {
        path: '/orders/edit/:id',
        name: 'OrdersEdit',
        component: () => import('@/views/OrdersView/OrdersEditView'),
        meta: {
          requiresAuth: true,
          accessModul: 'orders.edit',
        },
      },
      {
        path: '/orders/history/:id',
        name: 'OrdersHistory',
        component: () => import('@/views/OrdersView/OrdersHistoryView'),
        meta: {
          requiresAuth: true,
          accessModul: 'orders.history',
        },
      },
      {
        path: '/users',
        name: 'UsersView',
        component: () => import('@/views/UsersView'),
        meta: {
          requiresAuth: true,
          accessModul: 'users.view',
        },
      },
      {
        path: '/users/create',
        name: 'UsersCreateView',
        component: () => import('@/views/UsersView/UsersCreateViews'),
        meta: {
          requiresAuth: true,
          accessModul: 'users.create',
        },
      },
      {
        path: '/users/map/',
        name: 'UsersMapView',
        component: () => import('@/views/UsersView/UsersMapViews'),
        meta: {
          requiresAuth: true,
          accessModul: 'access.true',
        },
      },
      {
        path: '/spec-groups',
        name: 'UsersSpecGroupsView',
        component: () => import('@/views/UsersView/UserGroupsViews'),
        meta: {
          requiresAuth: true,
          accessModul: 'spec.groups',
        },
      },
      {
        path: '/users/edit/:id',
        name: 'UsersEditView',
        component: () => import('@/views/UsersView/UsersEditViews'),
        meta: {
          requiresAuth: true,
          accessModul: 'users.edit',
        },
      },
      {
        path: '/users/more/:id',
        name: 'UsersMoreView',
        component: () => import('@/views/UsersView/UsersMoreViews'),
        meta: {
          requiresAuth: true,
          accessModul: 'users.more',
        },
      },
      {
        path: '/roles/create',
        name: 'CreateRole',
        component: () => import('@/views/RolesView/CreateRoleViews'),
        meta: {
          accessModul: 'roles.create',
          requiresAuth: true,
        },
      },
      {
        path: '/roles/edit/:id',
        name: 'EditRole',
        component: () => import('@/views/RolesView/EditRoleViews'),
        meta: {
          accessModul: 'roles.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/roles/more/:id',
        name: 'MoreRole',
        component: () => import('@/views/RolesView/RolesMoreViews'),
        meta: {
          accessModul: 'roles.more',
          requiresAuth: true,
        },
      },
      {
        path: '/my-profile',
        name: 'MyProfile',
        component: () => import('@/views/UserProfileView'),
        meta: {
          accessModul: 'access.true',
          requiresAuth: true,
        },
      },
      {
        path: '/my-profile/edit',
        name: 'MyProfileEdit',
        component: () => import('@/views/UserProfileView/UserProfileEditView'),
        meta: {
          accessModul: 'access.true',
          requiresAuth: true,
        },
      },
      {
        path: '/cashbacks',
        name: 'CashbacksView',
        component: () => import('@/views/CashbacksView'),
        meta: {
          accessModul: 'cashbacks.view',
          requiresAuth: true,
        },
      },
      {
        path: '/user-types',
        name: 'UserTypes',
        component: () => import('@/views/UserTypesView'),
        meta: {
          accessModul: 'user-types.view',
          requiresAuth: true,
        },
      },
      {
        path: '/warehouse',
        name: 'Warehouse',
        component: () => import('@/views/WarehouseView'),
        meta: {
          accessModul: 'warehouse.delete',
          requiresAuth: true,
        },
      },
      {
        path: '/bot-chats',
        name: 'BotChats',
        component: () => import('@/views/BotChatsView'),
        meta: {
          accessModul: 'bot-chats.view',
          requiresAuth: true,
        },
      },
      {
        path: '/bot-chats/edit/:id',
        name: 'EditBotChats',
        component: () => import('@/views/BotChatsView/EditBotChatView'),
        meta: {
          accessModul: 'bot-chats.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/bot-chats/more/:id',
        name: 'MoreBotChats',
        component: () => import('@/views/BotChatsView/MoreBotChatView'),
        meta: {
          accessModul: 'bot-chats.more',
          requiresAuth: true,
        },
      },
      {
        path: '/blogs',
        name: 'BlogsView',
        component: () => import('@/views/BlogsView'),
        meta: {
          accessModul: 'blogs.view',
          requiresAuth: true,
        },
      },
      {
        path: '/blogs/create',
        name: 'CreateBlogsView',
        component: () => import('@/views/BlogsView/CreateBlogsView'),
        meta: {
          accessModul: 'blogs.create',
          requiresAuth: true,
        },
      },
      {
        path: '/blogs/edit/:id',
        name: 'EditBlogsView',
        component: () => import('@/views/BlogsView/EditBlogView'),
        meta: {
          accessModul: 'blogs.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/blogs/more/:id',
        name: 'MoreBlogsView',
        component: () => import('@/views/BlogsView/MoreBlogsView'),
        meta: {
          accessModul: 'blogs.more',
          requiresAuth: true,
        },
      },
      {
        path: '/contents',
        name: 'ContentsView',
        component: () => import('@/views/ContentsView'),
        meta: {
          accessModul: 'contents.view',
          requiresAuth: true,
        },
      },
      {
        path: '/contents/create',
        name: 'CreateContentsView',
        component: () => import('@/views/ContentsView/CreateContentsView'),
        meta: {
          accessModul: 'contents.create',
          requiresAuth: true,
        },
      },
      {
        path: '/contents/edit/:id',
        name: 'EditContentsView',
        component: () => import('@/views/ContentsView/EditContentsView'),
        meta: {
          accessModul: 'contents.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/contents/more/:id',
        name: 'MoreContentsView',
        component: () => import('@/views/ContentsView/MoreContentsView'),
        meta: {
          accessModul: 'contents.more',
          requiresAuth: true,
        },
      },
      {
        path: '/sliders',
        name: 'SlidersView',
        component: () => import('@/views/SlidersView'),
        meta: {
          accessModul: 'sliders.view',
          requiresAuth: true,
        },
      },
      {
        path: '/mobile-verions',
        name: 'MobileVersionsView',
        component: () => import('@/views/MobileVersionsView'),
        meta: {
          accessModul: 'mobile.versions.view',
          requiresAuth: true,
        },
      },
      {
        path: '/mobile-verions/create',
        name: 'CreateMobileVersionsView',
        component: () =>
          import('@/views/MobileVersionsView/CreateMobileVersionsView'),
        meta: {
          accessModul: 'mobile.versions.create',
          requiresAuth: true,
        },
      },
      {
        path: '/mobile-verions/edit/:id',
        name: 'EditMobileVersionsView',
        component: () =>
          import('@/views/MobileVersionsView/EditMobileVersionsView'),
        meta: {
          accessModul: 'mobile.versions.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/mobile-verions/more/:id',
        name: 'MoreMobileVersionsView',
        component: () =>
          import('@/views/MobileVersionsView/MoreMobileVersionsView'),
        meta: {
          accessModul: 'mobile.versions.more',
          requiresAuth: true,
        },
      },
      {
        path: '/stores',
        name: 'StoresView',
        component: () => import('@/views/StoresView'),
        meta: {
          accessModul: 'store.view',
          requiresAuth: true,
        },
      },
      {
        path: '/zakup-list',
        name: 'ZakupListView',
        component: () => import('@/views/ZakupListView'),
        meta: {
          accessModul: 'zakup.list.view',
          requiresAuth: true,
        },
      },
      {
        path: '/zakup-list/edit/:id',
        name: 'EditZakupListView',
        component: () => import('@/views/ZakupListView/EditZakupListView'),
        meta: {
          accessModul: 'zakup.list.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/zakup-list/more/:id',
        name: 'MoreZakupListView',
        component: () => import('@/views/ZakupListView/MoreZakupListView'),
        meta: {
          accessModul: 'zakup.list.more',
          requiresAuth: true,
        },
      },
      {
        path: '/specification',
        name: 'SpecificationView',
        component: () => import('@/views/SpecificationView'),
        meta: {
          accessModul: 'specification.more',
          requiresAuth: true,
        },
      },
      {
        path: '/specification/create',
        name: 'CreateSpecificationView',
        component: () =>
          import('@/views/SpecificationView/CreateSpecificationView'),
        meta: {
          accessModul: 'specification.create',
          requiresAuth: true,
        },
      },
      {
        path: '/specification/edit/:id',
        name: 'EditSpecificationView',
        component: () =>
          import('@/views/SpecificationView/EditSpecificationView'),
        meta: {
          accessModul: 'specification.edit',
          requiresAuth: true,
        },
      },
      {
        path: '/specification/more/:id',
        name: 'MoreSpecificationView',
        component: () =>
          import('@/views/SpecificationView/MoreSpecificationView'),
        meta: {
          accessModul: 'specification.more',
          requiresAuth: true,
        },
      },
      {
        path: '/specification-types',
        name: 'specificationTypeView',
        component: () => import('@/views/SpecificationTypesView'),
        meta: {
          accessModul: 'specification.types.view',
          requiresAuth: true,
        },
      },
      {
        path: '/specification-and-category',
        name: 'specificationAndCategoryView',
        component: () => import('@/views/SpecificationAndCategorys'),
        meta: {
          accessModul: 'specification.and.categorys.view',
          requiresAuth: true,
        },
      },
      {
        path: '/card-items',
        name: 'CartItemsView',
        component: () => import('@/views/CardItemsView'),
        meta: {
          accessModul: 'card.items.view',
          requiresAuth: true,
        },
      },
      {
        path: '/download-catalog-setting',
        name: 'DownloadCatalogSettingView',
        component: () =>
          import('@/views/ProductsView/DownloadCategorySettingView'),
        meta: {
          accessModul: 'product-download.catalog',
          requiresAuth: true,
        },
      },
      {
        path: '/403',
        name: '403Views',
        component: () => import('@/views/ErrorViews/403View'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/500',
        name: '500Views',
        component: () => import('@/views/ErrorViews/500View'),
        meta: {
          requiresAuth: false,
          accessModul: 'access.true',
        },
      },
      {
        path: '/offline',
        name: 'OfflineView',
        component: () => import('@/views/ErrorViews/OfflineView'),
        meta: {
          requiresAuth: false,
          accessModul: 'access.true',
        },
      },
      {
        path: '/shops',
        name: 'ShopsView',
        component: () => import('@/views/ShopsView'),
        meta: {
          requiresAuth: false,
          accessModul: 'shops.view',
        },
      },
      {
        path: '/shops/map',
        name: 'ShopsMapView',
        component: () => import('@/views/ShopsView'),
        meta: {
          requiresAuth: false,
          accessModul: 'shops.map',
        },
      },
      {
        path: '/suppliers',
        name: 'SuppliersView',
        component: () => import('@/views/SuppliersView'),
        meta: {
          requiresAuth: false,
          accessModul: 'suppliers.view',
        },
      },
      {
        path: '/suppliers/create',
        name: 'SuppliersCreateView',
        component: () => import('@/views/SuppliersView/CreateSuppliersView'),
        meta: {
          requiresAuth: false,
          accessModul: 'suppliers.create',
        },
      },
      {
        path: '/suppliers/edit/:id',
        name: 'SuppliersEditView',
        component: () => import('@/views/SuppliersView/EditSuppliersView'),
        meta: {
          requiresAuth: false,
          accessModul: 'suppliers.edit',
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404Notfound',
        component: () => import('@/views/ErrorViews/404View'),
        meta: {
          requiresAuth: true,
          accessModul: 'access.true',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLogged = store.getters.isLogged;
  if (isLogged && to.name === 'Login') {
    location.replace('/main');
    return;
  }

  const { matched } = to;
  if (matched.some((record) => record?.meta?.requiresAuth)) {
    if (isLogged) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router;
