import Vue from 'vue';
import Vuex from 'vuex';
import allAcsessData from './allPermissionsData.json';
import { secretKey } from '@/config';
import crypto from '@/plugins/crypto.js';

const refresh = localStorage.getItem('refresh');
const userInfo = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : [];
const theme = localStorage.getItem('theme') == 'dark' ? 'dark' : 'light';

Vue.use(Vuex);

const userAccessStore = localStorage.getItem('userAccess')
  ? JSON.parse(
      crypto.AES.decrypt(
        localStorage.getItem('userAccess'),
        secretKey
      ).toString(crypto.enc.Utf8)
    )
  : null;

export default new Vuex.Store({
  state: {
    isLogged: refresh !== null,
    userInfo,
    theme: theme || 'light',
    accsessData: userAccessStore,
    zakupListCach: { data: null, date: null },
    shopsListCach: { data: null, date: null },
    selectedProductIds: [],
  },
  getters: {
    isLogged({ isLogged }) {
      return isLogged;
    },
    userInfo({ userInfo }) {
      return userInfo;
    },
    theme({ theme }) {
      return theme;
    },
    userAccess({ accsessData }) {
      if (accsessData == 'noLoad' || !accsessData) {
        return 'noLoad';
      }
      accsessData.map((item) => {
        allAcsessData[item.module] = item.has_access;
      });
      return allAcsessData;
    },
    zakupListCach({ zakupListCach }) {
      return zakupListCach;
    },
    shopsListCach({ shopsListCach }) {
      return shopsListCach;
    },
    selectedProductIds({ selectedProductIds }) {
      return selectedProductIds;
    },
  },
  mutations: {
    setLogin(state, payload) {
      state.isLogged = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setTheme(state, payload) {
      state.theme = payload;
    },
    setAccessUser(state, payload) {
      state.accsessData = payload;
    },
    setZakupListCach(state, payload) {
      state.zakupListCach = payload;
    },
    setShopsListCach(state, payload) {
      state.shopsListCach = payload;
    },
    setSelectedProductIds(state, payload) {
      state.selectedProductIds = payload;
    },
  },
  actions: {
    changeUser({ commit }, userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      commit('setUserInfo', userInfo);
    },
    changeTheme({ commit }, theme) {
      commit('setTheme', theme);
    },
    changeAccsess({ commit }, accsessData) {
      commit('setAccessUser', accsessData);
    },
    changeZakupListCach({ commit }, zakupListCach) {
      commit('setZakupListCach', zakupListCach);
    },
    changeShopsListCach({ commit }, shopsListCach) {
      commit('setShopsListCach', shopsListCach);
    },
    changeSelectedProductIds({ commit }, selectedProductIds) {
      commit('setSelectedProductIds', selectedProductIds);
    },
  },
  modules: {},
});
