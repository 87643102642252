import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from 'vue';

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'https://app.dev.bozor.com/css/2215.6b786ae8.css',
    'https://app.dev.bozor.com/css/2277.82771aa4.css',
    'https://app.dev.bozor.com/css/5978.b53ad4ba.css',
    'https://app.dev.bozor.com/css/DahsboardView.b6a6b8ae.css',
    'https://app.dev.bozor.com/css/OrdersView.33a86e55.css',
    'https://app.dev.bozor.com/css/app.4ca568c0.css',
    'https://app.dev.bozor.com/css/chunk-vendors.046057ba.css',
  ],
};
Vue.use(VueHtmlToPaper, options);

export default Vue;
