import Vue from 'vue';

const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

Vue.prototype.$toLocalMoney = (value, formating) => {
  if (value == null) return '';
  if (!formating) {
    if (localStorage.getItem('currency') == 'usd') {
      return Number(value / getWithExpiry('currencyUSD')).toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
        }
      );
    } else {
      return String(Number(value).toFixed(0)).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ' '
      );
    }
  } else {
    if (localStorage.getItem('currency') == 'usd') {
      return Number(value / getWithExpiry('currencyUSD')).toFixed(2);
    } else {
      return Number(value).toFixed(2);
    }
  }
};

export default Vue;
